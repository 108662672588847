import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import strings from "../../Localization/LNG";
import { closeNotOpenModal } from "../../redux/actions/uiActions";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
const LoginRequired = ({}) => {
  const { notAuthModal } = useSelector((state) => state.ui);
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeNotOpenModal());
  };
  const history = useHistory();
  return (
    <Modal
      show={notAuthModal}
      keyboard={true}
      className={isAr ? "modal_ar" : ""}
      centered
      size="lg"
      dir={`${isAr ? "rtl" : "ltr"}`}
      onEscapeKeyDown={onClose}
      onHide={onClose}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <h3 className="main_color"> {strings.shared.mustLoginFirst} </h3>
        <p className="t-3 text-capitalize">{strings.shared.mustloginText}</p>
        <div className="mt-2">
          <Button
            style={{ padding: ".8rem 4rem " }}
            onClick={() => {
              onClose();
              history.push("/auth/login");
            }}
            primary
            className={`mt-4 ${isAr ? "text_ar" : ""} `}
          >
            {strings.nav.login}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginRequired;
