import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
// import "./assets/main.css";
import App from "./Containers/App/App";
import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./redux/store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import strings from "./Localization/LNG";

const lng = localStorage.getItem("lng");

strings.setLanguage(lng || "english");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
