import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeUnlockProfile } from "../../redux/actions/uiActions";
import "./unlock-company.scss";
import strings from "../../Localization/LNG";
import { ErrorMessage, Formik } from "formik";
import { Button } from "semantic-ui-react";
import Input from "../../Shared/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import enstanse from "../../utils/axios";
import { toast } from "react-toastify";
import CustomDropDown from "../custom-dropdown";
import _ from "lodash";
const UnlockProfile = () => {
  const { openModal, company } = useSelector((state) => state.ui.unlockCompany);
  const [step, setStep] = useState(1);
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const [loadingC, setLoadingC] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [companys, setCompanys] = useState([]);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const onClose = () => {
    dispatch(closeUnlockProfile());
    setStep(1);
    setFiles([]);
  };

  const getCompanys = async (term) => {
    setLoadingC(true);
    const res = await enstanse.get(`/autocomplete?key=company&q=${term}`);
    setLoadingC(false);
    const data = res.data.data;
    let dep = [];
    _.forEach(data, (d) => {
      dep.push({ key: d.name, text: d.name, value: d.name });
    });
    setCompanys(dep);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".png, .jpg, .jpeg, .pdf",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => {
    return (
      <div key={file.name}>
        <div className="row">
          <div className="col-4">
            <img
              width={"100%"}
              height={80}
              className="img-thumbnail rounded "
              src={file.preview}
              alt=""
            />
          </div>
          <div className="col" style={{ alignSelf: "center" }}>
            {file.name}
          </div>
        </div>
      </div>
    );
  });

  if (openModal) {
    return (
      <Modal
        show={openModal}
        keyboard={true}
        centered
        size="lg"
        className={`unlock_profile_wraper ${isAr ? "ar" : ""} `}
        dir={`${isAr ? "rtl" : "ltr"}`}
        onEscapeKeyDown={onClose}
        onHide={onClose}
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <div className="mt-3">
            <div className="text-center">
              <h4
                className={`title___ main_color text-center ${
                  isAr ? "text_ar" : ""
                } `}
              >
                {strings.unlock.title}
              </h4>
            </div>
            <Formik
              initialValues={{
                company: company ? company.name_en : "",
              }}
              validate={(values) => {
                const errors = {};
                if (!company) {
                  if (!values.company) {
                    errors.company = isAr
                      ? "من فضلك اختار الشركة"
                      : "Please enter company name";
                  }
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                var formData = new FormData();
                formData.append("commericial_registeration", files[0]);
                for (var key in values) {
                  formData.append(key, values[key]);
                }
                enstanse
                  .post(`/companies/unlock`, formData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => {
                    setSubmitting(false);
                    onClose();
                    setFiles([]);

                    toast(res.data.message, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      type: "success",
                    });
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    if (err && err?.response && err?.response?.data.message) {
                      toast(err.response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: "error",
                      });
                    }
                  });
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form
                  style={{ padding: "0 3rem" }}
                  className={`text-center mt-4 unlock_f ${isAr ? "ar" : ""} `}
                  onSubmit={handleSubmit}
                >
                  <div className="step_1">
                    <div className="form-group">
                      <label htmlFor="company_nmae">
                        {strings.auth.company_name}
                      </label>
                      {company ? (
                        <Input
                          name="company"
                          value={values.company}
                          className="text-center"
                          type="text"
                          disabled
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={strings.auth.company_name}
                        />
                      ) : (
                        <CustomDropDown
                          name="company"
                          className="select_form"
                          value={values.company}
                          loading={loadingC}
                          search
                          fluid
                          selection
                          onSearchChange={(e) => {
                            getCompanys(e.target.value);
                          }}
                          onChange={(e, value) => {
                            setFieldValue("company", value.value);
                          }}
                          onBlur={handleBlur}
                          options={companys}
                          placeholder={strings.auth.company_name}
                        />
                      )}

                      <ErrorMessage
                        className={`text-danger`}
                        component="span"
                        name="company"
                      />
                    </div>
                  </div>
                  <div className="upload_image form-group">
                    <div className="form-group">
                      <label htmlFor="company_nmae">
                        {strings.unlock.AuthorizationLetter}
                      </label>
                      <input
                        type="file"
                        onChange={handleChange}
                        {...getInputProps()}
                      />

                      <div
                        {...getRootProps({
                          className: "dropzone upload_images",
                        })}
                      >
                        {files.length > 0 ? (
                          thumbs
                        ) : uploading ? (
                          <div className="text-center">
                            <div
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <div className="_upload text-center">
                            <div className="text-center">
                              <div
                                style={{ fontSize: "22px" }}
                                className="icon d-inline-block"
                              >
                                <FontAwesomeIcon
                                  className="main_color mr-2 ml-2"
                                  icon={faCloudUploadAlt}
                                />
                              </div>
                              <span>
                                {strings.utils.dragAndDrop}
                                <button
                                  type="button"
                                  className="upload_button mr-2 ml-2"
                                >
                                  {strings.utils.browse}
                                </button>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <Button
                    primary
                    type={"submit"}
                    loading={isSubmitting}
                    style={{ padding: ".8rem 5rem" }}
                  >
                    {strings.newCompany.submit}
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    );
  } else return null;
};

export default UnlockProfile;
