import React, { useEffect, useState, useRef } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import strings from "../../../Localization/LNG";
import { Button } from "semantic-ui-react";
import enstanse from "../../../utils/axios";
import _ from "lodash";
// import { Form } from "react-bootstrap";
import CustomDropDown from "../../../Components/custom-dropdown";
import { Form, Row, Col, Radio } from "antd";
import Select from "../../../Shared/CustomComponents/Select/Select";
const { Option } = Select;

export default function Step2({ step, onNext }) {
  const [departments, setDepartments] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [employmentStatus, setEmploymentStatus] = useState([]);

  const isAr = strings.getLanguage() === "arabic" ? true : false;

  const getDepartment = async () => {
    const res = await enstanse.get("/departments");
    setDepartments(res.data.data);
  };

  const [levels, setJobLevels] = useState([]);
  const getJobLevel = async () => {
    const res = await enstanse.get(`/joblevels`);
    setJobLevels(res.data.data);
  };

  const getJobsTtile = async () => {
    const res = await enstanse.get(`/jobtitles`);
    setJobTitle(res.data.data);
  };

  const getEmpolymentStatus = async () => {
    const res = await enstanse.get(`/employment/status`);
    setEmploymentStatus(res.data.data);
  };

  useEffect(() => {
    getDepartment();
    getJobLevel();
    getJobsTtile();
    getEmpolymentStatus();
  }, []);

  return (
    <Form
      name="basic"
      layout="vertical"
      // className="form_trasform login_form_wrapper p-5 shadow-md border"
      onFinish={(values) => {
        onNext(values);
      }}
      initialValues={{
        joblevel: "",
        department: "",
        jobtitle: "",
        employment_status: "",
      }}
    >
      <Row className={`${isAr ? "text-right" : "text-left"}`}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={strings.submitReview.employmentStatus}
            name="employment_status"
            rules={[
              {
                required: true,
                message: strings.user.select_employment_Status,
              },
            ]}
          >
            <Select
              showSearch
              loading={!employmentStatus.length}
              optionFilterProp="children"
              style={{
                textAlign: isAr ? "right" : "left",
              }}
              placeholder={strings.shared.select}
              className="custom-select"
              size="large"
              allowClear
            >
              {employmentStatus?.map((job) => (
                <Option value={job.id}>{job?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={strings.auth.department}
            name="department"
            rules={[
              {
                required: true,
                message: strings.auth.validation.departmentRequired,
              },
            ]}
          >
            <Select
              showSearch
              loading={!departments.length}
              optionFilterProp="children"
              style={{
                textAlign: isAr ? "right" : "left",
              }}
              placeholder={strings.auth.department}
              className="custom-select"
              size="large"
              allowClear
            >
              {departments?.map((department) => (
                <Option value={department.id}>{department?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={strings.user.job_level}
            name="joblevel"
            rules={[
              {
                required: true,
                message: strings.user.select_job_title,
              },
            ]}
          >
            <Select
              showSearch
              loading={!levels.length}
              optionFilterProp="children"
              style={{
                textAlign: isAr ? "right" : "left",
              }}
              placeholder={strings.user.job_level}
              className="custom-select"
              size="large"
              allowClear
            >
              {levels?.map((department) => (
                <Option value={department.id}>{department?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={strings.auth.jobTitle}
            name="jobtitle"
            rules={[
              {
                required: true,
                message: strings.auth.select_job_title,
              },
            ]}
          >
            <Select
              showSearch
              loading={!jobTitle.length}
              optionFilterProp="children"
              style={{
                textAlign: isAr ? "right" : "left",
              }}
              placeholder={strings.auth.jobTitle}
              className="custom-select"
              size="large"
              allowClear
            >
              {jobTitle?.map((job) => (
                <Option value={job.id}>{job?.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <div
        className="d-flex mt-5 border-top"
        style={{ justifyContent: "space-between" }}
      >
        <div className="mt-3">
          <p className="main_color">
            {strings.newCompany.step} {step} {strings.newCompany.of} 3
          </p>
        </div>
        <div className="mt-3 d-flex">
          {step === 3 ? (
            <Button
              primary
              //     loading={isSubmitting}
              style={{ padding: ".8rem 4rem" }}
              type="submit"
            >
              {step === 3 ? strings.newCompany.submit : strings.newCompany.next}
            </Button>
          ) : (
            <Button primary style={{ padding: ".8rem 4rem" }} type="submit">
              {step === 3 ? strings.newCompany.submit : strings.newCompany.next}
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
}
