export const openShare = () => {
  return { type: "OPEN_SHARE" };
};

export const closeShare = () => {
  return { type: "CLOSE_SHARE" };
};

export const openNotAuthModal = () => {
  return { type: "OPEN_NOT_AUTH_MODAL" };
};

export const closeNotOpenModal = () => {
  return { type: "CLOSE_NOT_AUTH_MODAL" };
};

export const openUnlockModal = (company) => {
  return { type: "OPEN_UNLOCK_PROFILE", payload: company };
};

export const closeUnlockProfile = () => {
  return { type: "CLOSE_UNLOACK_PROFILE" };
};

export const closeablseModal = () => {
  return { type: "CLOSEBALE_ACCOUNT" };
};
export const CloseablseModal2 = (value) => {
  return { type: "CLOSEBALE", payload: value };
};
