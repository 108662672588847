import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ gotoTop }) {
  const { pathname } = useLocation();

  useEffect(() => {
    // window.scrollTo(0, 0);
    document.querySelector("body").scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname, gotoTop]);

  return null;
}
