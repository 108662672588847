import React, { useEffect } from "react";
import "./App.scss";
import { Helmet } from "react-helmet";
import ErrorBoundary from "../../utils/ErrorBoundary";
import "antd/dist/antd.css";

import strings from "../../Localization/LNG";
import "../../styles/footer.scss";
import Routes from "./routes";
import axios from "../../utils/axios";
import DirectionProvider, {
  DIRECTIONS,
} from "react-with-direction/dist/DirectionProvider";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import enstanse from "../../utils/axios";
import { Logout, onLogin } from "../../redux/actions/authAction";
import { useHistory, useLocation } from "react-router-dom";
import { closeablseModal } from "../../redux/actions/uiActions";
import { Button, Popup } from "semantic-ui-react";
import { ToastContainer } from "react-toastify";
import { ConfigProvider } from "antd";

// import { useNetworkStatus } from "react-adaptive-hooks/network";

function App() {
  const selctedLng = strings.getLanguage();
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  enstanse.defaults.headers.common["X-Language"] =
    strings.getLanguage() === "arabic" ? "ar" : "en";
  useEffect(() => {
    if (token) {
      axios
        .get(`/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res?.data.closeable === 1) {
            dispatch(closeablseModal());
          }
          dispatch(onLogin(token, res.data));
        });
    }
  }, []);

  useEffect(() => {
    if (user?.data.closeable === 1) {
      dispatch(closeablseModal());
    }
  }, [user?.data.closeable]);
  const history = useHistory();

  // axios.interceptors.response.use(
  //   (res) => {
  //     return res;
  //   },
  //   (error) => {
  //     if (error.response) {
  //       if (window.location.pathname === "/auth/login") {
  //         return Promise.reject(error);
  //       }
  //       if (error.response.status === 401) {
  //         localStorage.removeItem("persist:root");
  //         localStorage.clear();
  //         window.location.href = "/";
  //         window.location.reload();
  //         // message
  //       } else if (error.response.status === 403) {
  //         localStorage.removeItem("persist:root");
  //         localStorage.clear();
  //         window.location.href = "/";
  //         window.location.reload();
  //       }
  //       return Promise.reject(error);
  //     }
  //   }
  // );

  return (
    <div
      className={`app ${selctedLng === "arabic" ? "ar" : "en"} `}
      dir={selctedLng === "arabic" ? "rtl" : "ltr"}
      id="taakkad"
    >
      <Helmet>
        <title> Taakkad </title>
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
          integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
          crossorigin="anonymous"
        ></link>
      </Helmet>
      <ErrorBoundary>
        <div id="page-container">
          {/* <DirectionProvider
            direction={
              selctedLng === "arabic" ? DIRECTIONS.RTL : DIRECTIONS.LTR
            }
          > */}
          <ConfigProvider
            direction={
              selctedLng === "arabic" ? DIRECTIONS.RTL : DIRECTIONS.LTR
            }
          >
            <Routes token={token} />
          </ConfigProvider>
          {/* </DirectionProvider> */}
        </div>
      </ErrorBoundary>
      <ToastContainer />
    </div>
  );
}

export default App;
