import React, { useRef, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import strings from "../Localization/LNG";
import { closeShare } from "../redux/actions/uiActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
function ShareModal() {
  const { open } = useSelector((state) => state.ui.share);
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const dispatch = useDispatch();
  const [isCoped, setisCoped] = useState(false);
  const onClose = () => {
    dispatch(closeShare());
  };

  const inputRef = useRef();
  var url = window.location.href;

  return (
    <Modal
      show={open}
      keyboard={true}
      animation={false}
      centered
      size="sm"
      dir={`${isAr ? "rtl" : "ltr"}`}
      onEscapeKeyDown={onClose}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{}}>Share</Modal.Title>
        <div
          className="mt-5"
          // style={{ marginLeft: "-56px", border: "1px solid red" }}
        >
          <div className="">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              style={{ fontSize: "1.3rem" }}
              target="_blank"
              className="d-flex"
            >
              <div>
                <FontAwesomeIcon style={{ color: "blue" }} icon={faFacebookF} />
              </div>
              <div className="ml-4">Facebook</div>
            </a>
          </div>
          <div className="share_link_copy mt-3">
            <span className="text_muted" style={{ fontSize: "12px" }}>
              {isCoped ? "Link copied" : "Click to copy link"}
            </span>
            <input
              readonly="readonly"
              ref={inputRef}
              onClick={(e) => {
                setisCoped(true);
                inputRef.current.select();
                document.execCommand("copy");
              }}
              value={url}
              type="text"
              className="share_input"
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </Modal.Header>
    </Modal>
  );
}

export default ShareModal;
