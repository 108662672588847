import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import strings from "../Localization/LNG";
import Search from "../Containers/Home/Search/Search";

function ModalExampleCloseIcon(props) {
  const [open, setOpen] = React.useState(false);
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const textAlign = isAr ? "right" : "left";
  return (
    <Modal
      style={{
        height: "initial",
        top: "initial",
        left: "initial",
        overflow: "visible",
        direction: strings.getLanguage() === "arabic" ? "rtl" : "ltr",
      }}
      // closeIcon
      open={open}
      trigger={props.children}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header
        content={strings.ChooseCompany}
        style={{ textAlign: textAlign }}
      />
      <Modal.Content>
        <Search placeholder={strings.home.searchForCompany} setOpen={setOpen} />
      </Modal.Content>
    </Modal>
  );
}

export default ModalExampleCloseIcon;
