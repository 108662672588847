import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import strings from "../../Localization/LNG";
import enstanse from "../../utils/axios";
import { Button } from "semantic-ui-react";
import { CloseablseModal2 } from "../../redux/actions/uiActions";
import { onLogin } from "../../redux/actions/authAction";
import { toast } from "react-toastify";

const CloseableModal = () => {
  const { openCloseableModal } = useSelector((state) => state.ui);
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const { token, user } = useSelector((state) => state.auth);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const onActive = () => {
    setisLoading(true);
    enstanse
      .get(`/account/open`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        enstanse
          .get(`/profile`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setisLoading(false);
            dispatch(onLogin(token, res.data));
            toast(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              type: "success",
            });
            dispatch(CloseablseModal2(false));
          });
        // window.location.reload()
      })
      .catch((err) => {
        setisLoading(false);
        toast(
          err && err.response && err.response.data.message
            ? err.response.data.message
            : "An Error occured",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "error",
          }
        );
      });
  };

  if (openCloseableModal) {
    return (
      <Modal
        show={openCloseableModal}
        keyboard={true}
        className={isAr ? "modal_ar" : ""}
        centered
        size="lg"
        dir={`${isAr ? "rtl" : "ltr"}`}
        onEscapeKeyDown={() => {}}
        onHide={() => {}}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body className="text-center">
          <p className="t-3 text-capitalize mt-4">
            {strings.activeAccountCloseText}
          </p>
          <div className="mt-2">
            <Button
              style={{ padding: ".8rem 4rem " }}
              onClick={onActive}
              loading={isLoading}
              primary
              className={`mt-4 ${isAr ? "text_ar" : ""} `}
            >
              {strings.active}
            </Button>

            <Button
              style={{ padding: ".8rem 4rem " }}
              onClick={() => dispatch(CloseablseModal2(false))}
              primary
              className={`mt-4 ${isAr ? "text_ar" : ""} `}
            >
              {strings.Close}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  } else return null;
};

export default CloseableModal;
