import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import lngReducer from "./lngReducers";
import authReducder from "./authReducer";
import uiReducer from "./uiReducer";
import compare from "./compareReducer";
const appReducer = combineReducers({
  lng: lngReducer,
  auth: authReducder,
  ui: uiReducer,
  compare,
});
const root = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};
export default persistReducer(
  {
    whitelist: ["auth", "compare"],
    key: "root",
    storage: storage,
  },
  root
);
