const en = {
  Learn_More: "Learn More",

  siteName: "Taakkad",
  nav: {
    companys: "Companies",
    salaries: "Salaries",
    studies: "Studies",
    awards: "Awards",
    signup: "Sign up",
    login: "Login",
    for_employers: "For Employers",
    logout: "Logout",
    myProfile: "My Profile",
    signIn: "Login",
    home: "Home",
    overView: "company overview",
  },
  pageTitles: {
    home: "Home",
    virify: "Virify Your Account",
  },
  home: {
    show1: "Find Your Ideal Company",
    show2: "Compensation",
    show3: "Making Work Transparent & Rewarding",
    featuredCompany: "Featured Company",
    awardsText: "Best Place to Work Awards",
    topCompanys: "Top Companies",
    searchForCompany: "Search for Companies ...",
    noResultFound: "No Result Found",
    overallScore: "Overall Score",
    ceoRating: "CEO Rating",
    viewCompanyPage: "View Company Page",
    resentRated: "RECENTLY RATED COMPANIES",
    viewAllCompanys: "View All Companies",
    employees: "Employees",
    recentStudy: "RECENTLY Studies",
    vireAllStudy: "View All Study",
    seeAllResults: "See All Results",
    companyNotAdd: "Company not found , want to add it ? ",
    siteFeatures: {
      powerfulFeatures: "Powerful features",
      one: "UI/UX DESIGN",
      oneText: `Let you’ll one had. Gathering replenish behold open subdue signs place hath meat image fly. Whose together. Over there you, fill herb set had likeness male great living signs may. There moveth, above heaven said male.`,
      two: "PRESS",
      twoText: `Let you’ll one had. Gathering replenish behold open subdue signs place hath meat image fly. Whose together. Over there you, fill herb set had likeness male great living signs may. There moveth, above heaven said male.`,
      three: "DEVELOPMENT",
      threeText: `Let you’ll one had. Gathering replenish behold open subdue signs place hath meat image fly. Whose together. Over there you, fill herb set had likeness male great living signs may. There moveth, above heaven said male.`,
    },
  },

  compnay: {
    companyCopetitorsPlaceHolder: "There is no company competitors",
    ceoCopetitorsPlaceHolder: "There is no CEO competitors",
    interviewReviews: "There is no interview reviews yet",
    Benifits: "Benefits",
    searchForC: "Search Company & Comensation",
    companyReviewAndReating: " Company cultureText and ratings.",
    getTheWh: "Get the whole story.",
    companyText: ` Search ratings and reviews of over 600,000 companies
    worldwide. Get the inside scoop and find out what it's really
    like from people who've actually worked there.`,
    searchCPlaceholder: "Search for salaries , Companies & jobs",
    Reviewofemployee: "Review of employee",
    companies: "Companies",
    reviewsX: "Reviews",
    salary_review: "Salary Reviews",
    mission: "MISSION",
    vision: "vision",
    empolyeeParticpants: "PARTICIPANTS",
    total: "TOTAL",
    partings: "TOTAL RATINGS",
    socialStatistics: "Social Statistics",
    cultureScore: "Culture Score",
    cultureText: "Satisfaction compared to",
    overAllCulture: "Overall Culture",
    awards: "Awards",
    awardEmpty: "No Awards Found",
    seoScore: "CEO Score",
    noSeo: "No Seo Found",
    ralayRange: "Salary range",
    updateProfile: "Update Profile",
    Unlock: "Unlock",
    qa: {
      title: "Anonymous Q&A with",
      employees: "Employees",
      seeQuestions: "See questions about:",
      mostResetQa: "Most Recent Q&A",
      askQ: "Ask Question",
      added: "Added",
      answer: "Answer",
      answers: "answers",
      see: "See",
      anonymousQuestion: "Ask Anonymous Questions",
      selectCategory: "Select category",
      typeYourQuestions: "Type your Questions",
      send: "Send",
      plaseEnterTag: "Please enter tag",
      pleaseEnetrQuestion: "please enter question",
      workingAt: "Working at",
      competitors: "competitors",
      viewsAllQ: "View All Questions and Answers about",
      viewAll: "View all",
      questionsAbout: "questions about",
      pleaseEneterAnswer: "Please enter answer ",
      subitAnswer: "Submit Answer",
      whriteYourAnswer: "Write your answer here",
    },
    navigation: {
      overview: "Overview",
      reviews: "Reviews",
      salaries: "Salaries",
      interviews: "Interviews",
      competitors: "Competitors",
      culture: "Culture",
      qa: "Q&A",
      rateCompany: "Rate company",
      compareSalary: "Compare your Salary",
    },
    companyInfo: {
      companyInfo: "Company Info",
      website: "Website",
      headquarters: "Headquarter",
      headquartersCity: "Headquarters City",
      size: "Size",
      founded: "Founded",
      type: "Type",
      industry: "Industry",
      revenue: "Revenue",
      competitors: "Competitors",
      activity: "activity",
      phoneNumber: "Phone",
      Address: "Address",
    },
    reviews: {
      employyedReview: "Employee Reviews",
      reviewsEmpty: "Reviews Empty",
      reviewWarnignTExt: "selected this as a representative review",
      Search: "Search",
      searchByJobTitle: "Search job title",
      featuredReview: "Featured review",
      EmployeeReviewsbyCategory: "Employee Reviews by Category",
      RecentReviews: "Recent Reviews",
      SalaryReviews: "Salary Reviews",
      LeadershipReviews: "Leadership Reviews ",
      CareerpathReviews: " Careerpath Reviews",
      EnvironmentReviews: "Environment Reviews",
      InterviewReviews: "Interview Reviews",
      BenefitReviews: "Benefit Reviews",
      companyReviews: "company Reviews",
      EmployeeReviewsbyJobTitle: "Employee Reviews by Job Title",
      ReviewSentimentat: "Review Sentiment at",
    },
  },

  auth: {
    select_job_title: "Select Job Title",
    only_positive_numbers: "Only positive numbers",
    phone: "Phone",
    phone: "Phone",
    phone: "Phone",
    name: "Name",
    sign_title: "Sign In to Your Taakkad Account",
    reg_title: "Sign up & uncover the data for free",
    req_title_2: "Share your salary to see salaries for CEO",
    email: "Email Address",
    password: "Password",
    signIn: "Sign in",
    forgetPassword: "Forget Password",
    ResetForgottenPassword: "Reset Forgotten Password",
    ResetPassword: "Reset Password",
    googleBtn: "Continue With Google",
    appleAuth: "Continue With Apple",
    createPass: "Create Password",
    confirmPass: "Confirm Password",
    successAuth: "We Sent An Email To Your Email Please Verify That Email",
    privateMsg: "100% Anonymous & Private",

    or: "Or",
    verifyAccount: "Verify taakkad Account",
    yourEmailSuccessVerifyed: "Your email has been verified,",
    moreStep: `There's one more step to complete your registration.`,
    steps: {
      step1: "Step1: Job Title",
      step2: "Step2: Salary",
      step3: "Step3: Verification Account",
    },
    company_name: "Company Name",
    department: "Department",
    tag: "Category",

    jobTitle: "Job Title",
    city: "City",
    yourSalary: "Salary",
    bonus: "Bonus (%)",
    enterSalary: "Enter Your Salary",
    total: "Total",
    validation: {
      nameRequired: "Please Enter Name",
      phoneRequired: "Please Enter Phone",
      emailRequired: "Please Enter Email",
      invalidEmail: "Place Enter valid email address",
      passwordRequired: "Plaese Enter Password",
      companyRequired: "Please Enter company name",
      departmentRequired: "Please Enter department",
      jobtitleRequired: "Please Enter job title",
      cityRequired: "Please Enter city",
      salayRequired: "Please Enter Your Salary",
      salayMaxMin: "Please Enter Valid Salary ",
      bonus: "Please Enter Bonus",
      passwordCharcter:
        "password must be more than 8 charchter and les than 31 charchter",
      passwordNotSame: "Confirm Password Not Same password",
    },
  },
  user: {
    titile: "Enter basic Information",
    work_ex: "Work experience",
    select_work_ex: "Select Work experience",
    job_level: "Job level",
    select_job_title: "Select Job level",
    employment_Status: "Employment Status",
    select_employment_Status: "Blease Select Employment Status",
    city: "City",
    select_city: "Select City",
    gender: "Gender",
    selct_gender: "Blease Select Gender",
    age_group: "Age group",
    select_age_group: "Select Age group",
    company_name: "Company name",
    select_company_name: "Select Company name",
    education: "Qualifications",
    select_education: "Select Qualifications",
    nationality: "Nationality",
    select_nationality: "Select Nationality",
    female: "Female",
    male: "Male",
    validation: {
      workexperience: "Please Enter work experience",
      employment_status: "Please Enter employment status",
      education: "Please Enter Qualifications",
      joblevel: "Please Enter job Level",
      country: "Please Enter country",
      nationality: "Please Enter nationality",
      agegroup: "Please Enter age group",
      gender: "Please Enter gender",
      company: "Please Enter company Name",
      city: "Please Enter City",
    },
  },
  shared: {
    learnMore: "Learn more ..",
    readMore: "read more..",
    next: "Next",
    select: "Select",
    company: "Company",
    month: "Month",
    year: "Year",
    hour: "hour",
    mustLoginFirst: "You Must Login First",
    mustloginText: "before you do this action you must login to do so",
    yes: "Yes",
    no: "No",
    day: "Day",
    week: "Week",
    true: "True",
    false: "False",
    pros: "Pros",
    cons: "Cons",
    cancel: "Cancel",
    edit: "Edit",
    add: "Add",
    employes: "employes",
    more: "more",
  },
  footer: {
    ourCompany: "Our Company",
    aboutus: "About Us",
    press: "Press",
    terms: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    faq: "FAQs",
    product: "Product",
    home: "Home",
    interviews: "Interviews",
    contact: "Contact",
  },
  awards: {
    title: "Best Places to Work Awards",
    p: " Each quarter taakkad honors companies with Best Places to Work Awards, as rated by their own employees, across a variety of categories",
    bestCompanies: "Best Companies",
    showcaseAwards: "Showcase Your Company's Awards",
    showP: `Out of tens of thousands of companies you’ve been selected
    as an award winner. Showcase the awards you’ve earned and
    let everyone know your company’s culture stands out from the
    rest`,
    bestCEO: "Best CEO",
    wantToRate: "Want to rate your company?",
    wantRateShow: "Find your company’s page and submit your ratings.",
    wantRate2: "only takes a few minutes!",
    rateMyCompany: "Rate My Company",
    areUEmployee: "Are you an employer?",
    areUEmployeeShow: `
    Schedule a call with us and we'll help you create a winning employer brand strategy.`,
  },
  utils: {
    contactUs: "Contact us",
    iAgreetaakkad: "I agree to the Taakkad",
    termsOfUse: "Terms of Use.",
    reviewTermText: `This review of my experience at my current or former employer is truthful.`,
    allDepartment: "All Departments",
    allEx: "All Years Experience",
    allCitys: "All Cities",
    allEdQ: "All Edu. Qualifications",
    allEdS: "All Edu. Specializations",
    getStarted: "Get Started",
    age: "Age",
    uploadImage: "Upload Image",
    dragAndDrop: "Drag and drop files here or",
    browse: "Browse",
    commercial_photo: "Commercial Photo",
  },
  submitReview: {
    low: "low",
    medium: "medium",
    high: "high",
    number_validation: "Number should be between 0 and 100",
    yes: "Yes",
    no: "No",
    decline: "Decline",
    Happines: "Happines",
    rateCompany: "Rate",
    wouldLikeToContribute: "What would you like to contribute?",
    title1: "It only takes a minute! And your review will help.",
    title2: `Everything you add helps others find a company they'll love.Thanks`,
    AddAnonymous: "Add your anonymous...",
    companyReview: "Company Review",
    salary: "Salary Review",
    interViewReview: "Interview Review",
    cultureReview: "Culture Reviews",
    benefitsReviews: "Benefits Reviews",
    formerOrEmpoloyeer: "Are you a current or former employee?",
    current: "Current",
    former: "Former",
    Recruiter: "Recruiter",
    latsYearEmpolyee: "Last Year at Work",
    enterLatsYearEmpolyee: "Blease Enter Last Year at Work",
    required: "You must agree to the terms and conditions",
    employerName: `Employer Name`,
    enteremployerName: `من فضلك ادخل اسم الشركة`,
    neutral: "neutral",
    overAllRating: "please Enter Overall Rating",
    employmentStatus: "Employment Status",
    reviewHeadline: "Review Headline",
    pros: "Please Enter Pros",
    prosPlaceholder: "Share some of the best reasons to work at",
    cons: "Please Enter Cons",
    consPlaseHolder: "Share some of the downsides of working at",
    basePay: "base pay",
    currency: "currency",
    perDuration: "Blease Enter Review type",
    experience_years: "experience years",
    enterexperience_years: "Please Enter Experience Years",
    annual_raise: "Annual Raise",
    selectannual_raise: "Blease Enter Annual Raise",
    getBouns: "get bonus",
    selectgetBouns: "Please Enter your answer",
    cashBonus: "cash bonus",
    selectcashBonus: "Please Enter Valid Cash Bonus",
    cashBonusDuration: "type of cash bonus duration",
    entercashBonusDuration: "Blease Enter type of cash bonus duration",
    stockBounse: "stock bonus",
    enterstockBounse: "Blease Enter stock bonus",
    stockBounseDuration: "stock bonus duration",
    enterstockBounseDuration: "Please Enter Valid stock bonus duration",
    profitSharing: "Profit Sharing",
    enterprofitSharing: "Blease Select Profit Sharing",
    profitSharingDuration: "profit sharing duration",
    enterprofitSharingDuration: "Blease Enter profit sharing duration",
    salesCommission: "sales commission",
    selectsalesCommission: "Blease Enter Sales Commission",
    salesCommissionDuration: "sales commission duration",
    entersalesCommissionDuration:
      "Please Enter Valid Sales Commission Duration",
    tipsGratuities: "tips gratuities",
    entertipsGratuities: "Blease Enter Tips Gratuities",
    tipsGratuitiesDuration: "tips gratuities duration",
    entertipsGratuitiesDuration: "Blease Enter Tips Gratuities Duration",
    rate: "Rate",
    getBy: "get by",
    appliedOnline: "applied online",
    collecgeOrUn: "college or university",
    employeeReferral: "employee referral",
    inPerson: "in person",
    staffingAgency: "staffing agency",
    others: "other",
    didYougetAnOffer: "Did you get an offer?",
    stages: "stages",
    difficulty: "Difficulty",
    very_easy: "very easy",
    easy: "easy",
    average: "average",
    difficult: "difficult",
    very_difficult: "very difficult",
    duration: "Duration",
    duration_type: "duration type",
    how_helpful: "how helpful",
    very_helpful: "very helpful",
    helpful: "helpful",
    not_helpful: "not helpful",
    not_use_it: "not use it",
    description: "Description",
    question: "Question",
    answer: "Answer",
    positive: "Positive",
    negative: "negative",
    no_opinion: "no opinion",
    training: "Training",
    carearClarity: "Carear Clarity",
    carearConf: "Career Confidence",
    trustInTheFeture: "Trust In The Future Of The Company",
    trustInleadership: "Trust In The Leadership Of The Company",
    ceoRating: "CEO Rating",
    departmentManagementPerformance: "Department Managers Performance",
    inducement: "Inducement",
    fair_pay: "Fair Pay",
    team_efficiency: "Team Efficiency",
    work_creativity: "work creativity",
    work_excitement: "work excitement",
    average_working_hours: "average working hours",
    out_of_office_activities: "out of office activities",
    trust_in_leadership: "Trust in the leadership of the company",
    in_job_entertainment: "in-job entertainment",
    happines: "Happines",
    referral_to_others: "Referral To Others",
    general_environment: "General Environment",
    company_City: "Company City",
    employer_type: "Employer type",
    employee_numbers: "Employee Numbers",
    employee_status: "Employee Status",
    comment: "comment",
    select_witch_benefit: "Select which benefits are offered",
    un_sure: "un sure",
    bad_words: "Please Enter Bad word",
    one_word_msg: "Blease enter just one word",
    good_Words: "Please Enter Good word",
    goodWordsPlceholder: "Share some of the good words of working at ",
    badWordPlaceholder: "Share some of the bad words of working at",
    pleaseEneterjobTitle: "please enter job title",
    eneteryear: "please enter last year of employee",
    enetrjobLevel: "please enter job level",
    enterDepartment: "please select department",
    acceptTems: "please accept taakkad terms",
    enetrProce: "please enter pros",
    eneterCons: "please enter cons",
    enterHeadline: "Please enetr review headline",
    eneterBasePay: "please enter base pay ",
    enterCurrency: "please enter your currency",
    eneterPerDuration: "please enter per duration",
    reviewType: "Review Type",
    eneterEx: "please enter experience years",
    eneterAnuualRise: "please enter annual raise",
    enetergetbiunse: "please enter get bonus",
    eneterCity: "please enter city",
    enetrSalary: "please enter salary",
    enterDescription: "please enter description",
    enterRate: "Please inter Rate",
    PleaseSelect: "Please Select",
    selectDuration: "Please Select duration type",
    selectHowHelp: "please select how helpful",
    selecectQuestion: "Please Enter Question",
    difficultyRequired: "Please Select Difficulty",
    careerpath: "careerpath",
    environment: "environment",
    leadership: "leadership",
    enterGoodWord: "Please Enter Good Words",
    enterBadWord: "Please Enter Bad Words",
    Whatstageswhererequired: "What stages where required ?",
    InterviewType: "Interview Type",
    Testing: "Testing",
    Others: "Others",
  },
  forEmployers: {
    forEmployers: "For Employers",
    createFreeAcount: "Create Free Account",
    unlockYourProfile: "Unlock Your Profile",
    showT: "Showcase your branded messaging,",
    showcase2: "videos and photos",
    enshmarkYourSelf: "Benchmark yourself against competitors",
    employeeBanding: "What is Employer Branding?",
    employeeBrandText: `
                Employer Branding is the practice of defining, developing and
                managing your company’s reputation. A strong employer brand is
                essential to recruiting and retaining quality talent in today’s
                market and helps differentiate you from the competition. Learn
                the benefits of investing in employer branding, how it differs
                from your consumer brand, and see employer branding examples on
                Glassdoor in our Employer Branding FAQs.
    `,
    hightlight: "Highlight a great review ",
    getStartedFreeAcount: "Get started with your free employer account",
    YourName: "Your Name",
    OfficialJobTitle: "Official Job Title",
    WorkEmailAddress: "Work Email Address",
    WorkPhoneNumber: "Work Phone Number",
    CreateAccount: "Create Account",
    term1: "By signing up, you agree to the",
    termsofUse: "Terms of Use",
    and: "and",
    PrivacyPolicy: "Privacy Policy",
    pleaseEnterName: "Please enter your name",
    pleaseEnterCompany: "Please enter company name ",
    pleaseEnterJobTitle: "Please enter job title",
    enterEmail: "Please enter email address",
    pleaseEnterPhone: "Please enter your phone",
    pleseEnterPassword: "Please enter password",
    passwordLength: "The password must be at least 8 characters.",
    confirmPassword: "Please enter confirmatin passwod",
    passwordNotMatch: "password not match",
    EmployerProfile: "Employer Profile",
    Answerreviews: "Answer reviews",
    ComprehensiveAnalyticTool: "Comprehensive Analytic Tool",
    Surveys: "Surveys",
    toolsandAssets: "Tools and Assets",
    Setting: "Setting",
  },
  salarys: {
    compareYourSalary: " Compare your Salary",
    avrageSalary: "Average Salary",
    personlizedSalaray: "Get personalized salary insights",
    personalizeText: `Tell us about you and get an estimated calculation of how
    much you should be earning and insight into your career
    options.`,
    anulla_rase: "Annual Raise",
    EmployeesBonuses: "Employees Bonuses",
    salary_per_male: "Salaries per Male",
    salary_per_female: "Salaries per Female",
    average_compensation: "Average Compensation",
    median_compensation: "Median Compensation",
    salaryAverageByDep: "Salary Averages By Department",
    recentSalaries: "Recent Salaries Anonymously Contributed",
    salaryPlusBonus: "Salary + Bonus",
    averageForHight: "Average for highest paid by Education Qualification",
    salaryPerLocation: "Average salaries per Location",
    specialization_average:
      "Average for highest paid by Education Specialization",
    GetBonus: "Get Bonus",
    NotGetBonus: "Not Get Bonus",
  },
  studies: {
    questionTitle: "Are you satisfied with your Benefits ?",
    questions: "",
    answerdBy: "answerd by",
    inalldepartment: "in all department",
    howSayYes: '% who responded "Yes"',
    text: `77% of employees in the Executive department selected "Yes" when
    asked "Are you satisfied with your Benefits?", while only 59% of
    employees in the Legal department chose "Yes".`,
  },
  interviews: {
    shareYourInterview: "Share your Interview",
    interviewsAt: "Interviews at",
    experience: "Experience",
    getting_an_interview: "Getting an Interview",
    difficulty: "Difficulty",
    average: "Average",
    interviewApply: "Interview Apply",
    interviewType: "Interview Type",
    interviewTests: "Interview Tests",
    AnonymousInterviewCandidate: "Anonymous Interview Candidate",
    NoOffers: "No Offers",
    InterviewQuestions: "Interview Questions",
    shareExTitle:
      " Three reasons why you should Share your Interview experience!",
    qu: "Did you recently interview?",
    showWhar: "Share what it was like.",
    tip1: "Helping others helps you.",
    tip2: "Setting an example, for other job-seekers can give them a lot of hope.",
    tip3: "Your experience only hold value if you share it to benefit others.",
    VeryHard: "Very Hard",
    Hard: "Hard",
    Average: "Average",
    Easy: "Easy",
    veryeasy: "Very Easy",
  },
  culture: {
    environment: "Environment",
    workCreativity: "Work Creativity",
    JobAcceptance: "Job Acceptance",
    AverageWorkingHours: "Average Working Hours",
    OutOfOfficeActivities: "Out Of Office Activities",
    InJobEntertainment: "In-Job Entertainment",
    CareerPath: "Career Path",
    leadership: "Leadership",
    work_excitement: "Work Excitement",
    happines: "Team Efficiency",
    general_environment: "General Environment",
  },
  search: {
    company: "Company",
    crateAccountIfDontHaveThen: "Create an account if you don't have it, then ",

    notFounMsg: "Sorry , there are no companies matching",
    adjustSearch: "Adjust your search",
    checkSpilling: "check your spelling",
    role2: `Search for an acronym or abbreviation instead of the full
    name, or vice versa ('Procter & Gamble' vs 'P&G')`,
    secoundQ: "Are you a current or former employee of ",
    searchBy: `
    Search by the company's parent company ('Citi' instead of'Citibank')
    `,
    writeReview: "Write a review for ,",
    role3: `and our team will add the
    company to Taakkad.`,
    createCompanyProfile: "Create a company profile",
    giveTotaakkad: "a Presence on Taakkad",
    give: "Give",
    showcaseText: ` If you work in marketing or HR, you can give your company a
    presence on Taakkad by creating a Free Employer Account.
    Millions of people are already researching potential employers
    here — and your next great hire is one of them. Don’t let
    their search lead to a dead end.`,
    createFreeEm: " Create an free employer account",
  },
  newCompany: {
    addRequest: "Add Request For New Company",
    contactInfo: "Contact Information",
    about: "About",
    mission: "Mission",
    telphone: "Telphone",
    address: "Address",
    googleMap: "Google Map",
    linkedin: "Linkedin",
    twitter: "twitter",
    facebook: "facebook",
    website: "Website",
    youtube: "Youtube",
    instagram: "instagram",
    googlePlus: "Google Plus",
    CEO_Details: "CEO Details",
    CEO_Name: "CEO Name",
    CEO_Linkedin: "CEO Linkedin",
    step: "Step",
    back: "Back",
    of: "of",
    next: "Next",
    submit: "Submit",
    CEO_Photo: "CEO Photo",
  },
  profile: {
    others: "Others",
    help: "Help",
    comment: "Comment",
    certificates: "Certificates",
    certificates: "Certificates",
    certificates: "Certificates",
    skills: "Skills",
    experience: "Experience",
    education: "Education",
    Myprofile: "User info",
    e_mail_preference: "E-mail preference",
    myContributions: "My contributions",
    support: "Support",
    add_certificate: "add certificate",
    manageCertificates: "Manage certificates",
    noCert: "You did not add certificates yet",
    manageSkills: "Manage Skills",
    skillsPlaceholder: "Start Typing to add skills...",
    noSkills: "You did not add skills yet",
    addEducation: "add Education",
    manageEducation: "Manage Education",
    manageAbout: "Basic Info",
    manageAbout2: "Employment Details",
    Date: "Date",
    salary: "salary",
    gender: "Gender",
    save: "Save",
    contactUs: "Contact us",
    supportPleceholder: "Type your message ...",
    submit: "Submit",
    support: "Support",
    Type: "Type",
    complain: "complain",
    suggest: "suggest",
    manageEmail: "Manage E-mail preference",
    COMPENSATION_NOTIFICATIONS: "COMPENSATION NOTIFICATIONS",
    salaryUpdates: "Know Your Worth and Salary Updates",
    Announcement_marketing: "Announcement and marketing",
    ChangePassword: "Change Password",
    CloseAccount: "Close Account",
    addExp: "add Experience",
    at: "At",
    Basesalary: "Base salary",
    Bonus: "Bonus",
    exEmpty: "You did not add experiences yet",
    ManageExperience: "Manage Experience",
    From: "From",
    to: "To",
    certificateName: "Certificate name",
    issuedDate: "Issued date",
    UploadImage: "Upload Image",
    expireddate: "expired date",
    schoolName: "School name",
    Degree: "Degree",
    Fieldofstudy: "Field of study",
    From: "From",
    To: "To",
    issuedat: "issued at",
    exAt: "expired at",
    Grade: "Grade",
    Description: "Description",
    eneterSchool: "Please Enter school name",
    eneterDegree: "Please Enter degree",
    enetrFiled: "Please Enter field",
    enterGrade: "Please Enter Grade",
    enetrStartDate: "Please select started date",
    endDate: "Please select End date",
    fromRequired: "please Enetr From Date",
    certReq: "Please Enter certificate name",
    min_max: "Please Enter Valid Name(Minimum 2 Maximum 30)",
    issuedRequred: "Please Enter issued date",
    dateErrorValidationMsgFrom: "This date has not yet come !",
    dateErrorValidationMsgTo: "should not be less than the intiated date !",
    expiredDateRequred: "Please Enter expired date",
    enetertype: "Please Enter type",
    enetrMessage: "Please Enter Message",
    resonType: "Please enter reason type",
    enetrReason: "please enter your reasons",
    noEducation: "You did not add Education yet",
    noInterviewReviewsAdded: "No Interview Reviews Added",
    noCompanyReviewsAdded: "No Company Reviews Added",
    noQuestionAdded: "No Questions Added",
    questions: "Questions",
    views: "views",
  },
  compare: {
    compareIn: "Compare your Salary in",
    whereUWork: "Where do you work ?",
    totelPerMonth: "Total pay per month (SAR)",
    doUGetBonuses: ` Do you get Bonuses, Profit Sharing, Sales Commission or
    Annual Raise?`,
    annualLeave: "Valid Annual Leave",
    witchYearGetSalary: "Which year you got this Salary?",
    areUEmpolye: "Are you a Current or a Former employee?",
    specilization: "Specilization",
    SalaryComparisonResult: "Salary Comparison Result",
    BacktoCompanyprofile: "Back to Company profile",
    erroIfLogin: "You must Login to see all charts !",
    MainComparison: "Main Comparison",
    CashBonusComparison: "Cash Bonus Comparison",
    Annulaleave: "Annual leave",
    SalesCommissionComparison: "Sales Commission Comparison",
    AnnualRaiseComparison: "Annual Raise Comparison",
    ProfitSharing: "Profit Sharing Comparison",
    department: "Comparison for Consulting",
    enetrTotalPay: "Please Enter your total pay",
    enterAnnualLeave: "Please Enter Annual Leave",
    enterBouns: "Please Enter bouns",
    enetrJobLevel: "Please Select job level",
    is_formerRequired: "Please Select your curent stutus",
    nationalityRequired: "Please Enter nationality",
    specilizationRequired: "Please Select specilization",
    NationalityAverage: "Nationality Average",
    AverageIn: "Average in",
    specilizationRequired: "Please Enter Specialization",
    pleaseEnterYear: "Please Enter Year",
    yearNotValid: "Please Enter a valid date",
  },
  notifications: {
    review: "Company respond to your review",
    question: "some one respond to your question",
    like_dislike: "Liked/DisLike",
    salary: "Updates on my latest market value from know your worth",
    marketing: "Marketing",
    announcement: "Announcement",
  },
  unlock: {
    title: "Unlock profile after employer click on subscribe",
    CompanyWebsite: "Company Website",
    phone: "Phone Number",
    AuthorizationLetter: "Authorization Letter",
  },
  closeAccount: {
    title: "Why you need close your account ?",
    Typeyourreason: "Type your reason",
  },
  changPassword: {
    ChangePassword: "Change Password",
    OldPassword: "Old Password",
    NewPassword: "New Password",
    ConfirmNewpassword: "Confirm new password",
    pleasEnterOld: "Please Enter old password",
    enterNew: "please Enter new password",
    passwordConfirmation: "please enter password Confirmtion",
    passwordNotMatch: "password not match",
  },
  whatDegree: "What degrees do",
  employeeshold: "employees hold ?",
  edText: `There are 6 thimes more applicants whith a Bachelor's Degree than
  those with a Undisclose 63% of applicants have a Bachelor's Degree
  14% have no degree. 10% have Undisclose`,
  SAR: "SAR",
  min: "min",
  max: "max",
  Avg: "Avg",
  email: "Email",
  telephone: "Telephone",
  GitinTouch: "Get in touch",
  bySign: "By signing in, you agree to the",
  bySignUp: "By signing up, you agree to the",
  noResultFound: "no results found",
  add: "Add ",
  compareSubmit: "Rate",
  copy_right: "Taakkad - All rights reserved",
  Former: "Former",
  CurrentEmployee: "Current Employee",
  in: "in",
  SAR: "SAR",
  avg: "AVG",
  min: "Min",
  max: "Max",
  citys: "Cities",
  entercitys: "Please Enter City ",

  See: "",
  CompetitorsCompany: "with its main competitors",
  CompetitorsCEO: "with its main competitors",
  CulturevsCompetitors: "Culture vs Competitors:",
  CEORatingsComparison: "CEO Ratings Comparison",
  Showmore: "Show more",
  Showless: "Show less",
  getBouns: "Employees Bonuses",
  low: "Low",
  medium: "Medium",
  hight: "Height",
  NoBestCompaniesAwardsFound: "No Best Companies Awards Found",
  NoBestCEOAwardsFound: "No Best CEO Awards Found",
  WriteAReview: "Write a Review",
  Experience: "Experience",
  lorem:
    "Lorem ipsum dolor sit amet, ex tacimates adolescens sea, his ex rebum docendi tincidunt. In qui vitae epicuri scripserit, modo eros pericula , eam sapientem imperdiet conclusionemq.",
  country: "Country",
  specialization: "Specialization",
  enterspecialization: " Please Enter Specialization",
  pending: "pending",
  activeAccountCloseText: `Please know that
 If you do not activate your account, it will be closed automatically 30 days after you have blocked the account`,
  active: "Active",
  Close: "Close",
  reactiveted: "your account reactivated successfully",

  //////////////// new design /////////////
  overAllCultureScore: "Overall Culture Score",
  rateYourCompany: "Rate Your Company",
  ceoRate: "Ceo Rate",

  sar: "SAR",
  youEarn: "You earn",
  ltta: "less than the average",
  mtta: "more than the average",
  employee: "Employee",
  aecpb: "Average Employee Compensation + Bonus",
  msgNoQues: "We are sorry You dont Have any Questions",
  Experience: "Experience",
  Certificates: "Certificates",
  Education: "Education",
  Skills: "Skills",
  Bonus: "Bonus",
  bestSalary: "Best Salary",

  uaauq: "You Answered All Your Questions",
  userDashboard: "User Dashboard",
  yourRankin: "Your Ranking",
  // siteName: "You are unauth",
  unAuth: "You are not authorized to access you will be logged out",
  unAuth2: "You are unauthenticated we will redirect you to login page",
  ChooseCompany: "Choose company",
  noData: "No Data",

  showAnsBy: "Answered by",
  with: "with",

  show: {
    departments: "departments",
    city: "city",
    experience: "experience",
    education: "education",
    age: "age",
    gender_department: "gender department",
    gender: "gender",
    female: "Female",
    male: "Male",
  },
  NoStaticticHaveBeenFound: "No statictic have been found",
};
export default en;
