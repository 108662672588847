import Styled from "styled-components";
import { Input } from "antd";

// const Input = Styled.input`
//     width: 100%;
//     border: 0.10px solid #0a396230;
//     border-radius: 10px;
//     padding: .7rem;
//     // outline: none;
//     opacity: 1;
//     color: #474747;
//     background: #FCFCFC 0% 0% no-repeat padding-box;
//     &:focus {
//         border: 2px solid #0089ef;
//         background: white
//     }
// `;
import React from "react";

function InputCustom(props) {
  return (
    <Input
      {...props}
      style={{
        width: "100%",
        // border: "0.10px solid #0a396230",
        borderRadius: "10px",
        padding: ".7rem",
        // outline: "none",
        opacity: "1",
        color: "#474747",
        background: "#FCFCFC 0% 0% no-repeat padding-box",
      }}
    />
  );
}

export default InputCustom;

// export default Input;
