import React, { lazy } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ShareModal from "../../utils/ShareModal";
import CompantRate from "../CompanyRate/CompanyRate";
// import { ToastContainer, toast } from "react-toastify";
import LoadingLogo from "../../Components/LoadingLogo/LoadingLogo";
import strings from "../../Localization/LNG";
import GlobalStyle from "./GlobalStyle";
import LoginRequired from "../../Components/login-required";
import UnloackProfile from "../../Components/UnloackProfile";
import Wraper from "../../Components/wraper";
import { useSelector } from "react-redux";
import CloseableModal from "../../Components/account-closeable-modal";

const Home = lazy(() => import("../Home/Home"));
const Register = lazy(() => import("../Auth/Register/Register"));
const Login = lazy(() => import("../Auth/Login/Login"));
const Virify = lazy(() => import("../Auth/Virify/Virify"));
const CompanyRate = lazy(() => import("../CompanyRate/CompanyRate"));
const VirifyPasswordToken = lazy(() =>
  import("../Auth/ForgetPassword/ForgetPasswordViirfy")
);
const ForgetPassword = lazy(() =>
  import("../Auth/ForgetPassword/ForgetPassword")
);
const FourOfour = lazy(() => import("../../Components/404"));
const UserInformation = lazy(() => import("../Profile/UserInformation"));
const CompanyOverview = lazy(() =>
  import("../CompanyOverview/CompanyOverview")
);
const ForEmployerRegisteration = lazy(() =>
  import("../employer-registeration")
);
const Faq = lazy(() => import("../faq"));
const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy"));
const UpdatedCompanyProfile = lazy(() => import("../updated-company-profile"));
const Salarys = lazy(() => import("../Salarys"));
const Awards = lazy(() => import("../awards"));
const ForEmployes = lazy(() => import("../for-employers"));
const AddCompany = lazy(() => import("../create-company"));
const Search = lazy(() => import("../Search"));
const Companys = lazy(() => import("../companys"));
const Design = lazy(() => import("../Design"));

const Studies = lazy(() => import("../Studies"));
const ProfileManagement = lazy(() => import("../ProfileManage"));
const Compare = lazy(() => import("../compare"));
const CompareResult = lazy(() => import("../compareResult"));
const CreateRoutes = ({ token }) => {
  const selctedLng = strings.getLanguage();
  // const { token } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Wraper />
      <ShareModal />
      <UnloackProfile />
      <CloseableModal />
      <LoginRequired />
      <React.Suspense fallback={<LoadingLogo />}>
        <div id="content-wrap">
          <GlobalStyle lng={selctedLng} />

          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} />} />
            <Route
              path="/auth/register"
              render={(props) =>
                token ? (
                  <Redirect
                    to={{
                      pathname: "/",
                      state: { from: props.location },
                    }}
                  />
                ) : (
                  <Register {...props} />
                )
              }
            />
            <Route
              path="/auth/login"
              render={(props) =>
                token ? (
                  <Redirect
                    to={{
                      pathname: "/",
                      state: { from: props.location },
                    }}
                  />
                ) : (
                  <Login {...props} />
                )
              }
            />
            <Route
              path="/auth/forgotPassword"
              render={(props) =>
                token ? (
                  <Redirect
                    to={{
                      pathname: "/",
                      state: { from: props.location },
                    }}
                  />
                ) : (
                  <ForgetPassword {...props} />
                )
              }
            />
            <Route
              path="/password/reset/:token"
              render={(props) =>
                token ? (
                  <Redirect
                    to={{
                      pathname: "/",
                      state: { from: props.location },
                    }}
                  />
                ) : (
                  <VirifyPasswordToken {...props} />
                )
              }
            />
            <Route
              path="/auth/verify/:token"
              render={(props) => <Virify {...props} />}
            />
            <Route
              path="/company/:id"
              render={(props) => <CompanyOverview {...props} />}
            />
            <Route
              path="/rateCompany"
              render={(props) => <CompantRate {...props} />}
            />
            <Route
              path="/user/add_info"
              render={(props) => <UserInformation {...props} />}
            />
            <Route path="/search" render={(props) => <Search {...props} />} />
            <Route
              path="/for-employers/register"
              render={() => <ForEmployerRegisteration />}
            />
            <Route path="/studies" render={() => <Studies />} />
            <Route path="/add-Company" render={() => <AddCompany />} />
            <Route path="/for-employers" render={() => <ForEmployes />} />
            <Route path="/companies" render={() => <Companys />} />

            <Route path="/awards/:id" render={() => <Awards />} />
            <Route path="/salaries" render={() => <Salarys />} />
            <Route path="/compare/:id" render={() => <Compare />} />
            <Route
              path="/update/company/:id"
              render={() => <UpdatedCompanyProfile />}
            />
            <Route path="/compareResult" render={() => <CompareResult />} />
            <Route
              path="/user/myprofile"
              render={() => <ProfileManagement />}
            />
            <Route
              path="/privacy-policy"
              render={(props) => (
                <PrivacyPolicy {...props} url="privacy-policy" />
              )}
            />

            <Route
              path="/terms"
              render={(props) => (
                <PrivacyPolicy {...props} url="terms-condition" />
              )}
            />
            <Route
              path="/about-us"
              render={(props) => <PrivacyPolicy {...props} url="about-us" />}
            />
            <Route
              path="/press"
              render={(props) => <PrivacyPolicy {...props} url="press" />}
            />
            <Route path="/faq" render={(props) => <Faq {...props} />} />
            {/* <Route
                exact
                path="/*"
                render={(props) => <FourOfour {...props} />}
              /> */}

            <Route
              path="/user/dashboard"
              render={(props) =>
                token ? (
                  <Design {...props} url="user/dashboard" />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/auth/login",
                      state: { from: props.location },
                    }}
                  />
                )
              }
            />
          </Switch>
        </div>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default CreateRoutes;
