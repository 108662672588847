const INITIAL_STATE = {
  openLogin: false,
  unlockCompany: {
    openModal: false,
    company: undefined,
  },
  share: {
    open: false,
    url: null,
  },
  notAuthModal: false,
  openCloseableModal: false,
};

const uiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "OPEN_LOGIN_POP":
      return {
        ...state,
        openLogin: true,
        // url: action.payload,
      };
    case "CLOSE_LOGIN":
      return {
        ...state,
        openLogin: false,
      };
    case "OPEN_SHARE":
      return {
        ...state,
        share: {
          open: true,
          url: "https://g.co/kgs/qxR16T",
        },
      };
    case "CLOSE_SHARE":
      return {
        ...state,
        share: {
          open: false,
        },
      };
    case "OPEN_NOT_AUTH_MODAL":
      return {
        ...state,
        notAuthModal: true,
      };
    case "OPEN_UNLOCK_PROFILE":
      return {
        ...state,
        unlockCompany: {
          openModal: true,
          company: action.payload,
        },
      };
    case "CLOSE_UNLOACK_PROFILE":
      return {
        ...state,
        unlockCompany: {
          openModal: false,
          company: null,
        },
      };
    case "CLOSE_NOT_AUTH_MODAL":
      return {
        ...state,
        notAuthModal: false,
      };
    case "CLOSEBALE":
      return {
        ...state,
        openCloseableModal: action.payload,
      };
    default:
      return state;
  }
};

export default uiReducer;
