import { createStore, applyMiddleware, compose } from "redux";
import root from "../reducers/root";

import reduxThunk from "redux-thunk";
// import logger from "redux-logger";
import { persistStore } from "redux-persist";

const middleware = [reduxThunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const desctedLng = localStorage.getItem("lng");
let lng;
if (desctedLng === "arabic") {
  lng = "English";
} else {
  lng = "العربية";
}

export const store = createStore(
  root,
  {
    lng: {
      selected: lng,
    },
  },
  composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

export default { store, persistor };
