import React from "react";
import { Button } from "semantic-ui-react";
import PageWraper from "../Shared/PageWraper";

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return (
        <PageWraper>
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <img
                  src={require("../assets/Robot.svg")}
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="col-md-4">
                <p className="h1 main_color"> Ooopps </p>
                <p className="mt-3 h4">Looks like something went wrong</p>
                <a href="/" className="btn btn-link">
                  {" "}
                  Home{" "}
                </a>
              </div>
            </div>
          </div>
        </PageWraper>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
