import React, { useState, useEffect } from "react";
import strings from "../../Localization/LNG";
import { Link } from "react-router-dom";
import axios from "../../utils/axios";
export default function Footer() {
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const [data, setData] = useState({});
  useEffect(() => {
    axios.get(`/settings`).then((res) => {
      setData(res.data.data[0]);
    });
  }, []);
  return (
    <>
      <footer className="footer p-5 " id="footer">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-3">
              <img
                loading="lazy"
                src={require("../../assets/images/taakkad-logo.svg")}
                className="img-fluid logo"
              />
              <div
                className="store_icons d-flex mt-3"
                style={{ justifyContent: "space-evenly" }}
              >
                <a href="https://play.google.com/store/apps/details?id=appssquare.projects.takaad">
                  <img
                    src={require("../../assets/Google_Play_Store_badge_EN.svg")}
                    alt=""
                    style={{ width: "100px" }}
                  />
                </a>
                <a href="https://www.apple.com/app-store/">
                  <img
                    src={require("../../assets/640px-Download_on_iTunes.svg.png")}
                    alt=""
                    style={{ width: "100px", height: "29px" }}
                  />
                </a>
              </div>
            </div>
            <div
              className={`col-md-3 ${
                isAr
                  ? "text-md-right text-sm-center"
                  : "text-md-left text-sm-center"
              } `}
            >
              <div className="">
                <p className="col_title text-uppercase  font-weight-bold">
                  {strings.footer.ourCompany}
                </p>
                <ul className="p-0">
                  <li>
                    <Link to="/about-us"> {strings.footer.aboutus}</Link>
                  </li>
                  <li>
                    <Link to="/press"> {strings.footer.press} </Link>{" "}
                  </li>
                  <li>
                    <Link target="_blank" to={"/terms"}>
                      {" "}
                      {strings.footer.terms}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to="/privacy-policy">
                      {strings.footer.privacyPolicy}
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq"> {strings.footer.faq} </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`col-md-3 ${
                isAr
                  ? "text-md-right text-sm-center"
                  : "text-md-left text-sm-center"
              } `}
            >
              <div className="">
                <p className="col_title text-uppercase font-weight-bold">
                  {strings.footer.product}
                </p>
                <ul className="p-0">
                  <li>
                    <Link to="/"> {strings.footer.home} </Link>
                  </li>
                  <li>
                    <Link to="/companies"> {strings.nav.companys} </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/salaries"> {strings.nav.salaries} </Link>
                  </li>
                  <li>
                    <Link to="/studies"> {strings.nav.studies} </Link>{" "}
                  </li>
                  <li>
                    <Link to="/awards"> {strings.nav.awards} </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`col-md-3 ${
                isAr
                  ? "text-md-right text-sm-center"
                  : "text-md-left text-sm-center"
              } `}
            >
              <div className="">
                <p className="col_title text-uppercase font-weight-bold">
                  {strings.footer.contact}
                </p>
                <ul className="p-0">
                  <li>
                    <a href="mailto:info@taakkad.com">
                      {" "}
                      {strings.email} : {data?.email}
                    </a>
                  </li>
                  <li>
                    <a href="tel:+966 50 1234567">
                      {strings.telephone} :
                      <p style={{ direction: "ltr", display: "inline-table" }}>
                        {/* {data?.phone} */}
                        {isAr ? "قريبا" : "Comming soon"}
                      </p>
                    </a>
                  </li>
                  <p className="get_in_touch mt-4 text-uppercase">
                    {strings.GitinTouch}
                  </p>
                </ul>
                <div className="socile_link mt-3">
                  {/* <div className="icons">
                    <img
                      loading="lazy"
                      src={require("../../assets/icons/social/snapchat.svg")}
                      alt=""
                    />
                  </div> */}
                  <div className="icons">
                    <a target="_blank" title="twitter" href={data.twitter_url}>
                      <img
                        loading="lazy"
                        src={require("../../assets/icons/social/twitter-1.svg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="icons">
                    <a
                      target="_blank"
                      title="instagram"
                      href={data.instagram_url}
                    >
                      <img
                        loading="lazy"
                        src={require("../../assets/icons/social/instagram.svg")}
                        alt=""
                      />
                    </a>
                  </div>
                  {/* <div className="icons">
                    <img
                      loading="lazy"
                      src={require("../../assets/icons/social/linkedin.svg")}
                      alt=""
                    />
                  </div> */}
                  <div className="icons">
                    <a
                      target="_blank"
                      title="facebook"
                      href={data.facebook_url}
                    >
                      <img
                        loading="lazy"
                        src={require("../../assets/icons/social/facebook.svg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="icons">
                    <a target="_blank" title="youtube" href={data.youtube_url}>
                      <img
                        loading="lazy"
                        src={require("../../assets/icons/social/youtube.svg")}
                        alt=""
                      />
                    </a>
                  </div>
                  {/* <div className="icons">
                    <img
                      loading="lazy"
                      src={require("../../assets/icons/social/rss.svg")}
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {isAr ? (
        <div
          className="copy_right p-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "0px" }}>{strings.copy_right} </p>
          <p style={{ margin: "0px 5px" }}>&#169;</p>
          <p style={{ margin: "0px" }}>{new Date().getFullYear()}</p>
        </div>
      ) : (
        <div
          className="copy_right p-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "0px" }}>&#169;</p>
          <p style={{ margin: "0px 5px" }}>{new Date().getFullYear()}</p>
          <p style={{ margin: "0px" }}>{strings.copy_right} </p>
        </div>
      )}
    </>
  );
}
