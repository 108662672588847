import React, { useEffect, useState } from "react";
// import { Formik, ErrorMessage, Field } from "formik";
import strings from "../../../Localization/LNG";
import { Dropdown, Button } from "semantic-ui-react";
//import Input from "../../../Shared/Input";
import enstanse from "../../../utils/axios";
import _ from "lodash";
import * as Yup from "yup";
import { Form, Row, Col, Input, Radio } from "antd";
import CustomDropDown from "../../../Components/custom-dropdown";
import Select from "../../../Shared/CustomComponents/Select/Select";
import qs from "qs";
import { useLocation, useHistory, Link } from "react-router-dom";

const { Option } = Select;
export default function Step1({ step, onNext, companyName, data, company }) {
  const [companys, setCompanys] = useState([]);
  const [loadingC, setLoadingC] = useState(false);
  const isAr = strings.getLanguage() === "arabic" ? true : false;

  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const location = useLocation();

  const { type, id } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const rangedValues = range(currentYear, currentYear - 50, -1);
  let years = [];
  rangedValues.forEach((n) => years.push({ id: n, name: n }));

  const yearOptions = years;

  const getCompanys = async (term) => {
    setLoadingC(true);
    const res = await enstanse.get(`/autocomplete?key=company&q=${term}`);
    setLoadingC(false);
    const data = res.data.data;
    let dep = [];
    _.forEach(data, (d) => {
      dep.push({ id: d.name, name: d.name });
    });

    setCompanys(dep);
  };

  const [specilizations, setSpecilizations] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [citys, setCitys] = useState([]);

  const getQ = () => {
    enstanse.get(`/qualifications`).then((res) => {
      setQualifications(res.data.data);
    });
  };

  const getSpecilizations = (id) => {
    enstanse.get(`/qualifications/${id}/specilizations`).then((res) => {
      setSpecilizations(res.data.data);
    });
  };

  const getCitys = async (value) => {
    const res = await enstanse.get("/cities");
    setCitys(res.data.data);
  };
  useEffect(() => {
    getCitys();
    getQ();
  }, []);

  const [isFormer, setIsFormer] = useState("1");

  return (
    company && (
      <Form
        name="basic"
        layout="vertical"
        // className="form_trasform login_form_wrapper p-5 shadow-md border"
        onFinish={(values) => {
          onNext(values);
        }}
        initialValues={{
          employer_name: company?.name, //
          // review_type: type ? type : "company", //
          is_former: "1", //
          last_year: "", //
          education: "", ///
          specilization: "", ///
          cities: "", ///
        }}
      >
        <Row className={`${isAr ? "text-right" : "text-left"}`}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="form-group ">
              <label htmlFor="type" className="d-block">
                {strings.submitReview.AddAnonymous}
              </label>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.perDuration}
              name="review_type"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.reviewType,
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.AddAnonymous}
                onChange={(id) => {
                  //         setReviewType(id);
                }}
                className="custom-select"
                size="large"
                allowClear
              >
                {[
                  {
                    text: strings.submitReview.companyReview,
                    id: "company",
                  },

                  {
                    text: strings.submitReview.salary,
                    id: "salary",
                  },
                  {
                    text: strings.submitReview.interViewReview,
                    id: "interview",
                  },
                  {
                    text: strings.submitReview.cultureReview,
                    id: "culture",
                  },
                  {
                    text: strings.compnay.reviews.BenefitReviews,
                    id: "benifit",
                  },
                ].map((job) => (
                  <Option value={job.id}>{job?.text}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="is_former"
              label={strings.submitReview.formerOrEmpoloyeer}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.formerOrEmpoloyeer,
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setIsFormer(e.target.value);
                }}
              >
                <Radio value="1">{strings.submitReview.former}</Radio>
                <Radio value="0">{strings.submitReview.current}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {isFormer == 1 && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={strings.submitReview.latsYearEmpolyee}
                name="last_year"
                rules={[
                  {
                    required: true,
                    message: strings.submitReview.enterLatsYearEmpolyee,
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  loading={!yearOptions.length}
                  style={{
                    textAlign: isAr ? "right" : "left",
                  }}
                  placeholder={strings.shared.select}
                  className="custom-select"
                  size="large"
                  allowClear
                >
                  {yearOptions?.map((job) => (
                    <Option value={job.id}>{job?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.employerName}
              name="employer_name"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.employerName,
                },
              ]}
            >
              <Input
                size="large"
                style={{ borderRadius: 10 }}
                className="custom-input"
                placeholder={strings.submitReview.employerName}
                name="employer_name"
                disabled
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.user.education}
              name="education"
              rules={[
                {
                  required: true,
                  message: strings.user.validation.education,
                },
              ]}
            >
              <Select
                showSearch
                onChange={(value) => {
                  getSpecilizations(value);
                }}
                loading={!qualifications.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.user.education}
                className="custom-select"
                size="large"
                allowClear
              >
                {qualifications?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.specialization}
              name="specilization"
              rules={[
                {
                  required: true,
                  message: strings.enterspecialization,
                },
              ]}
            >
              <Select
                showSearch
                loading={!specilizations.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.specialization}
                className="custom-select"
                size="large"
                allowClear
              >
                {specilizations?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.citys}
              name="cities"
              rules={[
                {
                  required: true,
                  message: strings.entercitys,
                },
              ]}
            >
              <Select
                showSearch
                loading={!citys.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.citys}
                className="custom-select"
                size="large"
                allowClear
              >
                {citys?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div
          className="d-flex mt-5 border-top"
          style={{ justifyContent: "space-between" }}
        >
          <div className="mt-3">
            <p className="main_color">
              {strings.newCompany.step} {step} {strings.newCompany.of} 3
            </p>
          </div>
          <div className="mt-3 d-flex">
            {step === 3 ? (
              <Button
                primary
                //   loading={isSubmitting}
                style={{ padding: ".8rem 4rem" }}
                type="submit"
              >
                {step === 3
                  ? strings.newCompany.submit
                  : strings.newCompany.next}
              </Button>
            ) : (
              <Button primary style={{ padding: ".8rem 4rem" }} type="submit">
                {step === 3
                  ? strings.newCompany.submit
                  : strings.newCompany.next}
              </Button>
            )}
          </div>
        </div>
      </Form>
    )
  );
}
