import React from "react";
import { Dropdown } from "semantic-ui-react";
import strings from "../../Localization/LNG";
import "./custom-dropdown.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSearch } from "@fortawesome/free-solid-svg-icons";
export default function CustomDropDown(props) {
  console.log({ props });
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  return (
    <div
      className={`custome_dropdown ${
        strings.getLanguage() === "arabic" ? "ar" : "en"
      } `}
    >
      {isAr ? (
        <Dropdown
          scrolling={props.scroll ? true : false}
          // defaultValue={props.name === "country" ? 133 : null}
          wrapSelection={false}
          selectOnNavigation={false}
          icon={() => (
            <FontAwesomeIcon
              className={`float-left`}
              style={props.searchIcon ? { transform: "translateY(3px)" } : {}}
              icon={props.searchIcon ? faSearch : faSortDown}
            />
          )}
          name="type"
          noResultsMessage={strings.noResultFound}
          className="select_form"
          {...props}
          additionLabel={strings.add}
        />
      ) : (
        <Dropdown
          selectOnNavigation={false}
          name="type"
          className="select_form"
          {...props}
        />
      )}
    </div>
  );
}
