import React from "react";
import Styled from "styled-components";
import { Loader } from "semantic-ui-react";
const SpinnerLogoWraper = Styled.div`
 align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
`;

export default function LoadingLogo() {
  return (
    <SpinnerLogoWraper>
      <img src={require("../../assets/logos/taakkad-logo.svg")} alt="Logo" />

      <Loader active className="mt-3" inline="centered" />
    </SpinnerLogoWraper>
  );
}
