import axios from "axios";
import strings from "../Localization/LNG";
import { toast } from "react-toastify";

// export const BASE_URL = "https://app-beta.taakkad.com"; // => old
export const BASE_URL = "https://final.taakkad.com"; // => new
//test

const enstanse = axios.create({
  baseURL: BASE_URL + "/api",
  headers: {
    // "X-Language": strings.getLanguage() === "arabic" ? "ar" : "en",
    "X-device": "web",
    Accept: "application/json",
  },
});
enstanse.interceptors.request.use((req) => {
  console.log(`${req.method} ${req.url}`);
  // Important: request interceptors **must** return the request.
  return req;
});
enstanse.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err && err.response && err.response.data.message) {
      if (err.response.status == 403 && err.response.config.url != "/login") {
        window.localStorage.removeItem("persist:root");
        toast(strings.unAuth, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "error",
          onClose: () => {
            window.location.replace("/");
          },
        });
      } else if (
        err.response.status == 401 &&
        err.response.config.url != "/login"
      ) {
        window.localStorage.removeItem("persist:root");
        toast(strings.unAuth2, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "error",
          onClose: () => {
            window.location.replace("/auth/login");
          },
        });
      } else return Promise.reject(err);
    }
    // Important: response interceptors **must** return the response.
    // return err;
  }
);
export default enstanse;
