// import { CHANGE_LNG } from "../types";
import strings from "../../Localization/LNG";

const INITIAL_STATE = {
  selected: "English",
  code: "en",
};

const lngReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_LNG":
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};

export default lngReducer;
