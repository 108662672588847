import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
   * , h1  ,.ui.search.dropdown > .text{
   font-family : ${(props) => (props.lng === "arabic" ? "Cairo" : "Cairo")}
  }
  ${(props) =>
    props.lng === "arabic" &&
    `
     .custom-checkbox {
          padding-right: 1.5rem;
       }

      .custom-control-label::before , .custom-control-label::after{
          right: -1.5rem;
      }

         .react-rater-star.is-active-half:before {
      right: 0 !important;
      left: unset;
    }

   `}
   .swiper-wrapper {
      display: flex;
    align-items: center;
   }



 .ant-select-dropdown-rtl {
      direction: rtl;
      text-align:right;
  }
  .ant-select-rtl .ant-select-arrow, .ant-select-rtl .ant-select-clear {
   right: auto;
    left: 20px;
    top: 75%;
}
.ant-form-item-explain-error {
   margin: 15px 0 8px;
}

.ant-select-rtl.custom-select {
   background-position-x: left;
   background-position-y: center;
   padding:.375rem .375rem 1.75rem .75rem !important;
   background: no-repeat;
}


.swiper_prev_button , .swiper_next_button{
   margin:5px;
   width: 23px;
   height: 23px;
   justify-content: center;
   display: flex;
   align-items: center;
   padding: 0;
}


.ant-statistic-title {
   margin-bottom: 4px;
   color: #096dd9;
   font-size: 18px;
}
.media-body {
   width: 71%;
}




#taakkad[dir=rtl] .ui.dropdown>.dropdown.icon {
   margin: 0 .6em 0 0;
}
`;

export default GlobalStyle;
