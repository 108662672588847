import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import strings from "../../Localization/LNG";
import { ErrorMessage } from "formik";
class DynamicForm extends Component {
  state = {
    questions: [{ question: "", answer: "" }],
  };

  handleChange = (e) => {
    if (["question", "answer"].includes(e.target.className)) {
      let questions = [...this.state.questions];
      questions[e.target.dataset.id][e.target.className] = e.target.value;
      this.setState({ questions });
      this.props.onSetQuestions(questions);
    } else {
      this.setState({ [e.target.name]: e.target.value });
      this.props.onSetQuestions({ [e.target.name]: e.target.value });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
  };
  render() {
    const { questions } = this.state;
    return (
      <div className="dynamic_form_wraper">
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
          {questions.map((val, idx) => {
            let qId = `question-${idx}`,
              answerId = `answer-${idx}`;
            return (
              <div className="form-group required">
                <label htmlFor={qId}> {strings.submitReview.question} </label>
                <ErrorMessage
                  name="questions"
                  className={"text-danger mx-3"}
                  component="span"
                />
                <input
                  className="question"
                  type="text"
                  value={questions[idx].question}
                  name={qId}
                  data-id={idx}
                  style={style}
                />
                <div>
                  <label htmlFor={answerId}>
                    {" "}
                    {strings.submitReview.answer}{" "}
                  </label>
                  <textarea
                    className="answer"
                    cols="30"
                    rows="3"
                    value={questions[idx].answer}
                    type="text"
                    style={style}
                    name={answerId}
                    data-id={idx}
                  />
                </div>
              </div>
            );
          })}
        </form>
        <div style={{ paddingBottom: "4rem" }}>
          <Button
            type="button"
            style={{ float: "right" }}
            onClick={() => {
              this.setState((prevState) => ({
                questions: [
                  ...prevState.questions,
                  { question: "", answer: "" },
                ],
              }));
            }}
          >
            Add Question
          </Button>
        </div>
      </div>
    );
  }
}

const style = {
  width: "100%",
  border: "0.10px solid #0a396230",
  borderRadius: "10px",
  padding: ".7rem",
  outline: "none",
  opacity: 1,
  color: "#474747",
  background: "#FCFCFC 0% 0% no-repeat padding-box",
};

export default DynamicForm;
